import React from 'react';
import { format } from 'date-fns';
import './FlightSearchFields.css';

const FlightSearchFields = ({ flightSearchFields }) => {
    const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd');
return (
    <div className="flight-search-container">
        <div className="search-fields">
            
            <div className="field">
                <label>Going: </label>
                <div>
                    {formatDate(flightSearchFields.flight_itineraries[0].departure_time)}
                </div>
            </div>
            <div className="field">
                <label>Back: </label>
                <div>
                    {flightSearchFields.flight_itineraries[1] ? formatDate(flightSearchFields.flight_itineraries[1].departure_time) : 'N/A'}
                </div>
            </div>
            
        </div>
    </div>
    );
};

export default FlightSearchFields;


