// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth'; // Firebase auth listener
import { auth } from './firebase'; // Adjust your Firebase import path

import LandingPage from './LandingPage';
import ChatInterface from './ChatInterface';
import FlightPage from './pages/FlightPage'; // Import the test page
import FlightSearchFieldsPage from './pages/FlightSearchFieldsPage';
import AuthenticationPage from './pages/AuthenticationPage/AuthenticationPage';

import appLogo from './assets/voia_chat_icon.png';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state while checking auth

  // Firebase auth listener to check if the user is authenticated
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // If user is authenticated, set to true
      setLoading(false); // Stop loading once the check is complete
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  if (loading) {
    // Optionally render a loading state while checking auth status
    return <div>Loading...</div>;
  }


  return (
    <Router>
      <Routes>
        {/* Redirect root ("/") to "/chat" */}
        <Route path="/" element={<Navigate to="/chat" />} />
        
        <Route 
          path="/chat" 
          element={isAuthenticated ? <ChatInterface /> : <AuthenticationPage />} 
        />
        <Route path="/auth" element={<AuthenticationPage />} />
      </Routes>
    </Router>
  );
}

export default App;