import React from 'react';
import logo from './assets/site_logo_banner_white.png'; // Adjust the path to your logo
import chat_screen from './assets/chat_page 2.png'; // Adjust the path to your logo

import './LandingPage.css'; // Import CSS

const LandingPage = () => {

  const scrollToStayUpdated = () => {
    const stayUpdatedSection = document.getElementById('stayUpdated');
    stayUpdatedSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="container">
      <div className="wrapper">
      {/* Header */}
      <header className="header">
      {/* Replace the text with an image */}
      <img src={logo} alt="Company Logo" className="logo" />
      </header>

      {/* Hero Section */}
      <section id="home" className="heroSection">
        <div className="heroContent">
          <div className="textContainer">
            <h2 className="heroTitle">Voyage Organizer for Inspiring Adventures</h2>
            <p className="heroSubtitle">
            Crafting the ultimate AI travel agent to inspire and organize your next trips.
            </p>
            <div className="buttonContainer">
            <button className="joinWaitingListButton"  onClick={scrollToStayUpdated}>Join Waiting List</button>
            </div>
          </div>
          <div className="imageContainer">
            <img src={chat_screen} alt="chat_screen" className="heroImage" />
          </div>
        </div>
      </section>
      </div>


      {/* About Section */}
      <section id="about" className="aboutSection">
        <h2 className="sectionTitle">About the Project</h2>
        <p className="sectionText">
          Welcome to VOIA — your future AI-powered travel companion! 
        </p>
        <p className="sectionText">
          We are developing an intelligent platform that will transform the way you plan your adventures. Whether you are looking for inspiration or the most efficient itinerary, VOIA will use cutting-edge technology to curate personalized travel plans tailored to your unique preferences. Our mission is simple: to make travel planning as exciting as the journey itself.
        </p>
        <p className="sectionText">
          Stay tuned as we work to bring you the ultimate tool for organizing the best travel experiences.
        </p>
      </section>

      {/* Features Section */}
      <section id="features" className="featuresSection">
        <h2 className="sectionTitleWhite">What to Expect</h2>
        <div className="featureGrid">
          <div className="featureCard">
            <h3>AI Travel Agent</h3>
            <p>Chat with VOIA to plan your next trip. Whether you are looking for flight options, booking your next trip, or just need some travel inspiration, VOIA is there to help.</p>
          </div>
          <div className="featureCard">
            <h3>Itinerary Management</h3>
            <p>An innovative interface where you can manage all your trips' itineraries. We will provide you with calendar views and a trip dashboard to keep track of your travel plans.</p>
          </div>
          <div className="featureCard">
            <h3>On-Travel Support</h3>
            <p>Let's make sure you keep your days organized while you are traveling. You will have access to your daily itinerary to make sure you don't miss a beat.</p>
          </div>
        </div>
      </section>

      {/* Stay Updated Section */}
      <section id="stayUpdated" className="stayUpdatedSection">
        <h2 className="sectionTitle">Stay Updated</h2>
        <p className="sectionText">
          Want to be the first to know when we launch? Join the Waiting List to get all updates of our progress and don't miss out.
        </p>
        <form className="contactForm">
          <input type="email" placeholder="Enter your email" className="inputField" />
          <button type="submit" className="joinWaitingListButton">Join Waiting List</button>
        </form>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 Voia Travel. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;