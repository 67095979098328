import React, { useState } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore'; // Firestore functions
import { auth, firestore } from '../../firebase';
import { useNavigate } from 'react-router-dom'; // React Router navigation hook

import './AuthenticationPage.css'; // Import your CSS
import voia_logo_letters_white_square from '../../assets/voia-logo-letters-white-square.png'; // Adjust the path to your logo

const AuthenticationPage = () => {
    const navigate = useNavigate(); // Navigation hook
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);

    const handleToggle = () => {
        setIsLogin(!isLogin);
        setError(null);
        setPassword(''); // Clear password fields when toggling between login and signup
        setConfirmPassword(''); // Clear confirm password field when toggling
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (isLogin) {
            try {
                await signInWithEmailAndPassword(auth, email, password);
                console.log('User logged in');
                navigate('/chat'); // Redirect to chat after successful login
            } catch (error) {
                setError(error.message);
            }
        } else {
            if (password !== confirmPassword) {
                setError('Passwords do not match');
                return;
            }

            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;
                console.log('User signed up');

                // Save user data to Firestore
                await setDoc(doc(firestore, 'users', user.uid), {
                    email: user.email,
                    displayName: email.split('@')[0], // Deriving a simple username from email
                    createdAt: new Date(),
                    profilePicture: '', // Placeholder for profile picture URL
                    preferences: {
                    theme: 'light', // Default preference
                    notifications: true
                    }
                });

                navigate('/chat'); // Redirect to chat after successful signup
            } catch (error) {
                setError(error.message);
            }
        }
    };

    return (
        <div className="auth-box">
            <img className="voia-logo" src={voia_logo_letters_white_square} alt="Voia Logo" />

            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />
                {!isLogin && (
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        required
                    />
                )}
                <button type="submit">{isLogin ? 'Login' : 'Sign Up'}</button>
            </form>
            {error && <p className="error">{error}</p>}
            <p className="toggle-link">
                {isLogin ? "Don't have an account?" : 'Already have an account?'}{' '}
                <span onClick={handleToggle}>
                    {isLogin ? 'Sign Up' : 'Login'}
                </span>
            </p>
        </div>
    );
};

export default AuthenticationPage;
