import React, { useMemo } from 'react';
import FlightCard from '../components/FlightCard/FlightCard'; // Import the FlightCard component

// Sample flight data
const flightData = {
  "_id": "66ec7c333b523b62bebf8b96",
  "price": "45.34",
  "currency": "EUR",
  "flight_itineraries":[
    {
      "flight_itinerary_id":"92726a939da6062d88d876caa4b6eadf6330807c944d1d28d30c15372aee7c9a",
      "departure_airport":"MAD",
      "arrival_airport":"BCN",
      "departure_time":"2024-10-02T10:15:00",
      "arrival_time":"2024-10-02T14:20:00",
      "duration_minutes":"PT4H5M",
      "flights":[
        {
        "flight_id":"f9fe6b575e0fefa83f8ef014c6fa84c6411b50bb17e5b935ba8d822384c16d4f",
        "flight_number":"6013",
        "airline":"UX",
        "departure_airport":"MAD",
        "arrival_airport":"PMI",
        "departure_time":"2024-10-02T10:15:00",
        "arrival_time":"2024-10-02T11:35:00",
        "duration_minutes":"PT1H20M",
        "terminal":null
        },
        {
        "flight_id":"f78493daba14ff1d74b8f0c8efc629b82941175d95a50ee946f3162101348c05",
        "flight_number":"6060",
        "airline":"UX",
        "departure_airport":"PMI",
        "arrival_airport":"BCN",
        "departure_time":"2024-10-02T13:30:00",
        "arrival_time":"2024-10-02T14:20:00",
        "duration_minutes":"PT50M",
        "terminal":null
        }
      ]
    },
    {
      "flight_itinerary_id":"50ccc71e24d643a5b867f8ea0307c630b7c41921eaa589b5a8c6efa62d563e08",
      "departure_airport":"BCN",
      "arrival_airport":"MAD",
      "departure_time":"2024-10-05T18:45:00",
      "arrival_time":"2024-10-05T22:35:00",
      "duration_minutes":"PT3H50M",
      "flights":[
        {
        "flight_id":"71190359decfbd5c66e14cb88467e8ea393965334d0487056b6b89972f17a818",
        "flight_number":"6073",
        "airline":"UX",
        "departure_airport":"BCN",
        "arrival_airport":"PMI",
        "departure_time":"2024-10-05T18:45:00",
        "arrival_time":"2024-10-05T19:30:00",
        "duration_minutes":"PT45M",
        "terminal":null
        },
        {
        "flight_id":"0eb5fadc7524325893561ed9efe8742f0b022cb59c087b681c2cd60c8a7c2f9f",
        "flight_number":"6096",
        "airline":"UX",
        "departure_airport":"PMI",
        "arrival_airport":"MAD",
        "departure_time":"2024-10-05T21:10:00",
        "arrival_time":"2024-10-05T22:35:00",
        "duration_minutes":"PT1H25M",
        "terminal":null
        }
      ]
    }
  ]
};

const FlightPage = () => {
  return (
    <div>
      <h1>Flight Information</h1>
      <FlightCard flightData={flightData} />
    </div>
  );
};

console.log(flightData);

export default FlightPage;