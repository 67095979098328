import React from 'react';
import { format } from 'date-fns';
import './FlightCard.css';

const FlightCard = ({ flightData }) => {
  const formatTime = (time) => format(new Date(time), 'HH:mm');
  const formatDuration = (duration) => {
    const hours = duration.match(/(\d+)H/);
    const minutes = duration.match(/(\d+)M/);
    return `${hours ? hours[1] + 'h' : ''}${minutes ? minutes[1] : ''}`;
  };
  const getStops = (flights) => {
    const numStops = flights.length - 1;
    return numStops === 0 ? 'Direct' : `${numStops} stop${numStops > 1 ? 's' : ''}`;
  };
  const getAirlines = (flights) => {
    const airlines = flights.map(flight => flight.airline);
    // Using Set to remove duplicates, then converting it back to an array
    const uniqueAirlines = [...new Set(airlines)];
    return uniqueAirlines.join(', '); // Join to display as a string if needed
  };

  const getAirlineLogo = (airline) => {
    const airlineImageName = airline.replace(/\s+/g, ''); // Removing any spaces
    return `./assets/airlines/logo/${airlineImageName}.png`; // Dynamic path to the airline logo
  };

  return (
    <div className="flight-card">
        
        <div className="flight-info-wrapper">
            {flightData.flight_itineraries.map((itinerary, index) => (
            <div key={index} className="flight-info">
            
            
            {/* Left Column: Airline Logos */}
            <div className="airlines">
                <img 
                  src={getAirlineLogo(getAirlines(itinerary.flights))} 
                  alt={getAirlines(itinerary.flights)} 
                  className="airline-logo" 
                  onError={(e) => { 
                    e.target.src = '/assets/airlines/logo/default.png'; // Fallback logo in case of missing airline logo
                  }} 
                />
            </div>

            {/* Left Column: Departure Info */}
            <div className="departure">
                <div className="flight-time">{formatTime(itinerary.departure_time)}</div>
                <div className="airport">{itinerary.departure_airport}</div>
            </div>

            {/* Middle Column: Arrow and Duration */}
            <div className="duration-arrow">
                <div className="duration">{formatDuration(itinerary.duration_minutes)}</div>
                <div className="arrow"></div> {/* Custom CSS arrow */}
                <div className="stops-info">{getStops(itinerary.flights)}</div>
            </div>

            {/* Right Column: Arrival Info */}
            <div className="arrival">
                <div className="flight-time">{formatTime(itinerary.arrival_time)}</div>
                <div className="airport">{itinerary.arrival_airport}</div>
            </div>
            </div>
            ))}
        </div>
        

        <div className="price-column">
        <div className="price">
            {flightData.price} {flightData.currency}
        </div>
        </div>
    </div>
    );
};

export default React.memo(FlightCard);


