import React, { useState, useEffect, useCallback, useRef} from 'react';
import './ChatInterface.css';
import FlightCard from './components/FlightCard/FlightCard'; // Import the FlightCard component
import FlightSearchFields from './components/FlightSearchFields/FlightSearchFields'; // Import the FlightCard component

import voia_chat_icon from './assets/voia_chat_icon.png'; // Adjust the path to your logo
import voia_letters_gradient from './assets/voia-letters-gradient.png'; // Adjust the path to your logo

import { onAuthStateChanged } from 'firebase/auth';
import { signOut } from 'firebase/auth';
import { auth, getIdToken } from './firebase'; // Adjust path based on your firebase.js setup

function ChatInterface() {

  console.log("API URL:", process.env.REACT_APP_API_URL);

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatId, setChatId] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [flightData, setFlightData] = useState([]);
  const [userId, setUserId] = useState(null);


  const messagesEndRef = useRef(null);  // Add a ref for the end of the chat 

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchMessages = useCallback(async (selectedChatId = chatId) => {

    if (!selectedChatId) return;

    try {
      const token = await getIdToken(); // Get the Firebase ID token
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat/${selectedChatId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in Authorization header
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log(data)
      const formattedMessages = data.map(message => ({
        role: message.role,
        name: message.name,  // Include message.name to ensure it's not omitted
        content: message.content,
      }));
      setMessages(formattedMessages || []);
      // Iterate through messages to find those with .role === 'function' and .name === 'search_flight'
        data.forEach(message => {
            if (message.role === 'function' && message.name === 'search_flight') {
            try {
                // Assuming message.content is a JSON string of flight data or flight IDs
                const flightData = JSON.parse(message.content);
                setFlightData(flightData);  // Set the flight data
            } catch (error) {
                console.error('Error parsing flight data:', error);
            }
            }
        });
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  }, [chatId]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out successfully");
      // Optionally, redirect to the login page or update the UI
      // For example, you can navigate to the login page or clear user-specific data:
      window.location.href = '/auth'; // Redirect to authentication page (adjust URL as needed)
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const fetchChatList = useCallback(async () => {
    try {
      const token = await getIdToken(); // Get the Firebase ID token
      console.log(`TOKEN: ${token}`)
      console.log(`USER ID: ${userId}`)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat/${userId}/list`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in Authorization header
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.length === 0) {
        console.log(`DATA: empty chat list`)
      }
        setChatList(data);

        // Check if there are no chats for the user (new user)
        if (!chatId && data.length === 0) {
            // Automatically create a new chat if no chats exist
            const newChatId = await handleNewChat();
            setChatId(newChatId); // Select the new chat
            console.log(`NEW CHAT ID: ${newChatId}`)
        } else if (data.length > 0 && !chatId) {
            const lastChatId = data[0];
            setChatId(lastChatId);  // Set the last chat ID as the selected chat
            await fetchMessages(lastChatId);  // Fetch messages for the last chat
            console.log(`LAST CHAT ID: ${lastChatId}`)
        }
    } catch (error) {
      console.error('Error fetching chat list:', error);
    }
  }, [userId, chatId, fetchMessages]);

  const fetchFlightData = async (flightIds) => {
    try {
      const token = await getIdToken(); // Get the Firebase ID token
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/flights`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in Authorization header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ flight_ids: flightIds }), // Send flight IDs to the backend
      });
  
      const flightData = await response.json();
      setFlightData(flightData.flights); // Assuming the response contains the flight data in 'flights'
      /*setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'system', name: 'flight_data', content: flightData }, // Add the fetched flight data to the messages
      ]);*/
    } catch (error) {
      console.error('Error fetching flight data:', error);
    }
  };

  // Fetch chat list only when `userId` is set (after login)
  useEffect(() => {
    if (userId) {
      fetchChatList(); // Fetch chat list when userId is available
    }
  }, [userId, fetchChatList]);
  
  // Fetch messages only when a valid `chatId` is selected
  useEffect(() => {
    if (chatId) {
      fetchMessages(chatId); // Fetch messages when a valid chatId is selected
    }
  }, [chatId, fetchMessages]);
  
  // Scroll to bottom whenever new messages are added
  useEffect(() => {
    scrollToBottom();  // Call scrollToBottom when messages are updated
  }, [messages]);

  
  // Set userId when the user logs in/out via Firebase Auth
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, get their UID
        setUserId(user.uid);
      } else {
        // User is signed out
        setUserId(null);
      }
    });
  
    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === '') return;

    try {

        const newUserMessage = { role: "user", content: newMessage, name:"" };
        setMessages(prevMessages => [...prevMessages, newUserMessage]);

        const token = await getIdToken(); // Get the Firebase ID token
    
        // POST request to add the new message
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat/${chatId}/message`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in Authorization header
                'Content-Type': 'application/json',
            },
            // TODO: Add context messages to the conversation
            body: JSON.stringify({
                "messages": [
                {"role": "user", "content": newMessage} // Adjust the role as needed
                ],
                "model": "gpt-4o-mini"
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to send message');
        }

        const data = await response.json();

        const formattedMessages = data.map(message => ({
            role: message.role,
            name: message.name || "",  // Include message.name to ensure it's not omitted
            content: message.content,
          }));

        // Add the AI's response to the messages
        if (formattedMessages) {
            formattedMessages.forEach(message => {
                console.log(message)
                setMessages(prevMessages => [...prevMessages, {message, role: message.role, content: message.content, name: message.name || "" }]);
                if (message.role === 'function' && message.name === 'search_flight') {
                    setFlightData(message.content);
                }
            });
            }

        // Clear the input field
        setNewMessage('');
    } catch (error) {
      console.error('Error submitting message:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleNewChat = async () => {

    try {

      const token = await getIdToken(); // Get the Firebase ID token

      // POST request to create a new chat
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat/start`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in Authorization header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId }),
      });
  
      const data = await response.json();
      
      // Set the new chat ID
      const newChatId = data.conversation_id;
      setChatId(newChatId);  // Automatically select the new chat
  
      // Add the new chat to the chat list
      setChatList((prevChatList) => [...prevChatList, newChatId]);
  
      // Fetch messages for the new chat
      await fetchMessages(newChatId);
  
      return newChatId;  // Return the new chatId to be used elsewhere
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  const handleChatSelect = (selectedChatId) => {
    setChatId(selectedChatId);
    fetchMessages(selectedChatId);
  };

  return (
    <div className="ChatInterface">
      {/* Add recaptcha-container div (invisible, no need to style) */}
      <div className="sidemenu">
        <div className="side-menu-button" onClick={handleNewChat}>
          <span>+</span>
          New chat
        </div>
        <div className="chat-list">
          {chatList.map((chat_id) => (
            <div
              key={chat_id}
              className={`chat-card ${chat_id === chatId ? 'active' : ''}`}
              onClick={() => handleChatSelect(chat_id)}
            >
              {chat_id}
            </div>
          ))}
        </div>
      </div>
      <div className="chatbox">
        <div className="chat-banner">
            <img src={voia_letters_gradient} alt="Voia Letters Gradient" className="banner-image" />
            <button onClick={handleLogout} className="logout-button">
                Logout
            </button>
        </div>
        <div className="chat-log">
            {messages
            .filter(message => message.role !== 'system')  // Filter out 'system' messages
            .map((message, index) => (
                <div
                key={index}
                className={`chat-message ${message.role} ${message.name ? message.name.replace('_', '') : ''}`}
                >
                <div className="chat-message-center">
                    {message.role === 'function' && message.name === 'search_flight' ? (
                    (() => {
                        // Safely parse message.content and handle potential errors
                        try {
                            const flightData = JSON.parse(message.content); // Parse the message content
                    
                            // Check if flightData is an array, and render FlightSearchFields + FlightCard
                            return (
                              <>
                                {/* Render the FlightSearchFields */}
                                <FlightSearchFields
                                  key={flightData[0].flight_trip_id}
                                  flightSearchFields={flightData[0]}
                                />
                    
                                {/* Render FlightCard for each flight_trip */}
                                {Array.isArray(flightData) ? (
                                  flightData.map((flight_trip) => (
                                    <FlightCard key={flight_trip.flight_trip_id} flightData={flight_trip} />
                                  ))
                                ) : (
                                  <div>Invalid flight data format</div>
                                )}
                              </>
                            );
                          } catch (error) {
                            console.error('Error parsing flight data:', error);
                            return <div>Error loading flight data</div>;
                          }
                    })()
                    ) : (
                    <>
                        {/* Default  behavior for other messages */}
                        <div className={`message-container ${message.role === 'user' ? 'user-message' : ''}`}>
                            {message.role === 'assistant' && (
                                <div className="avatar">
                                    <img src={voia_chat_icon} alt="Voia Chat Logo" />
                                </div>
                            )}
                            <div className="message">{message.content}</div>
                        </div>
                    </>
                    )}
                </div>
                </div>
            ))}
        <div ref={messagesEndRef} /> {/* This will act as the reference to scroll to */}
        </div>
        
        <div className="chat-input-holder">
            <form onSubmit={handleSubmit}>
            <textarea
                rows="1"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                className="chat-input-textarea"
                placeholder="Type your message here"
            ></textarea>
            <button type="submit" className="chat-submit-button">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5L12 19M12 5L18 11M12 5L6 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            </form>
        </div>
      </div>
    </div>
  );
}

export default ChatInterface;


